import React, { Suspense } from "react";
import isKioskMode from "../../libs/isKioskMode";

const WebApp = React.lazy(() => import("./WebApp/WebApp"));
const KioskApp = React.lazy(() => import("./KioskApp/KioskApp"));

export default function App() {
  return (
    <Suspense fallback={<div>...</div>}>
      <>
        {isKioskMode() && <KioskApp />}
        {!isKioskMode() && <WebApp />}
      </>
    </Suspense>
  );
}
